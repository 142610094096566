import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HTTP_STATUS, API_URL } from "../../../config/Constants";
import axios from "axios";
import Checkerror from "../../../components/Errors";

const namespace = "customer";

export const getServiceData= createAsyncThunk(`${namespace}/getServices`, async () => {
  try {
    const { data } = await axios.get(`${API_URL}/${namespace}/getServices`);
    return data;
  } catch (error) {
    return Checkerror(error);
  }
});

export const getServiceDataSlice = createSlice({
  name: namespace,
  initialState: {
    loading: "",
    data: [],
  },
  reducers: {},
  extraReducers: {
    [getServiceData.pending](state, action) {
      state.loading = HTTP_STATUS.PENDING;
    },
    [getServiceData.fulfilled](state, action) {
      state.loading = HTTP_STATUS.FULFILLED;
      state.data = action.payload;
    },
    [getServiceData.rejected](state, action) {
      state.loading = HTTP_STATUS.REJECTED;
    },
  },
});



export const getSingleService = createAsyncThunk(
    `${namespace}/getSingleService`,
    async (id) => {
      try {
        const { data } = await axios.get(`${API_URL}/${namespace}/getSingleService/${id}`);
        return data;
      } catch (error) {
        return Checkerror(error);
      }
    }
  );

export const getSingleServiceSlice = createSlice({
    name: namespace,
    initialState: {
      loading: "",
      data: [],
    },
    reducers: {},
    extraReducers: {
      [getSingleService.pending](state, action) {
        state.loading = HTTP_STATUS.PENDING;
      },
      [getSingleService.fulfilled](state, action) {
        state.loading = HTTP_STATUS.FULFILLED;
        state.data = action.payload;
      },
      [getSingleService.rejected](state, action) {
        state.loading = HTTP_STATUS.REJECTED;
      },
    },
  });